import { faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getVentaInfoVObyPartId } from '../../shared/services/incidencias';
import imgPiezaEjemplo from '../../../../images/etiquetaPieza.jpeg';
import { toast } from 'react-toastify';

export default function Step0 ({
    onPartChecked
}) {
    const { t } = useTranslation();
    const [ventaInfo, setVentaInfo] = useState(null);
    const [showContact, setShowContact] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            id_referencia_plataforma: ''
        }
    });

    useEffect(() => {
    }, []);

    const handleSubmitSearch = async (data, e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await getVentaInfoVObyPartId(data.id_referencia_plataforma).catch((error) => {
            console.log(error);
            toast.error('error');
            setIsLoading(false);
        });

        if (response && response.success) {
            setVentaInfo(response.data);
            setIsLoading(false);
        }
    };

    const handleConfirmChoice = (confirm) => {
        if (confirm) {
            onPartChecked(
                {
                    ...ventaInfo.venta.venta,
                    cliente: ventaInfo.venta.cliente,
                    piezas: ventaInfo.venta.piezas
                },
                ventaInfo.tipoIncidencias
            );
        } else {
            setShowContact(true);
        }
    };

    return (
        <div className='w-100'>
            <div className="w-100 d-flex flex-column justify-content-center">
                <div className="w-100">

                    { (!ventaInfo) &&
                        <form
                            className="form flex-column flex-lg-row align-items-stretch"
                            onSubmit={handleSubmit(handleSubmitSearch)}
                        >
                            <div className='col-12 col-lg-2'></div>

                            <div className='col-12 col-lg-4 mb-4 mb-lg-0 me-0 me-lg-4'>
                                <img src={imgPiezaEjemplo} alt='Imagen de ejemplo' style={{ width: '100%', height: 'auto' }} />
                            </div>

                            <div className='col-12 col-lg-4 d-flex flex-column align-items-stretch'>
                                <div className='mb-4 mb-lg-0'>
                                    <p className='text-start'> ¡{ t('common.hi') }!</p>
                                    <p className='text-start'>{ t('crm-views.incident.explanation-step0') }</p>
                                </div>

                                <div className='d-flex flex-column'>
                                    <div className='form-group mb-0 flex-row align-items-center'>
                                        <label htmlFor=''></label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            {...register('id_referencia_plataforma')}
                                            defaultValue={''}
                                            onChange={(e) => setValue('id_referencia_plataforma', e.target.value)}
                                        />
                                        <button
                                            type='submit'
                                            className='btn btn-success ms-3'
                                            disabled={isLoading}
                                        >
                                            { (isLoading)
                                                ? <FontAwesomeIcon icon={faSpinner} size="1x" className="action" spin color="#FFF" />
                                                : <FontAwesomeIcon icon={faMagnifyingGlass} size="1x" className="action" color="#FFF" />
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-2'></div>
                        </form>
                    }

                    { (ventaInfo && !showContact) &&
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                            <div className='col-12 col-lg-8'>
                                <div className='w-100 d-md-block d-none'>
                                    <p>{ t('common.hi') } <span className="fw-bold">{ventaInfo?.venta.cliente.nombre}</span>! <br /></p>
                                    <p>{ t('crm-views.incident.confirm') }</p>

                                    <table className='table mt-4'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{ t('crm-views.incident.brand') }</th>
                                                <th>{ t('crm-views.incident.article') }</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { ventaInfo.venta.piezas?.map(pieza => (
                                                <tr key={`pieza-${pieza.id_referencia_plataforma}`}>
                                                    <td>{pieza.id_referencia_plataforma}</td>
                                                    <td>{pieza.marca} {pieza.modelo}</td>
                                                    <td>{pieza.nombre}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className='w-100 d-block d-md-none'>
                                    <p>{ t('common.hi') } <span className="fw-bold">{ventaInfo?.venta.cliente.nombre}</span>! <br /></p>
                                    <p>{ t('crm-views.incident.confirm') }</p>

                                    { ventaInfo.venta.piezas?.map(pieza => (
                                        <div
                                            key={`pieza-${pieza.id_referencia_plataforma}`}
                                            className='mt-4 d-flex flex-column align-items-start '
                                        >
                                            <p className='text-start'># {pieza.id_referencia_plataforma}</p>
                                            <p className='text-start'><span className='fw-bold'>{ t('crm-views.incident.brand') }</span>: {pieza.marca} {pieza.modelo}</p>
                                            <p className='text-start'><span className='fw-bold'>{ t('crm-views.incident.article') }</span>: {pieza.nombre}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className='w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-center gap-4 mt-5'>
                                    <button
                                        type='button'
                                        className='btn btn-primary flex-grow-1 flex-md-grow-0'
                                        onClick={() => handleConfirmChoice(true)}
                                    >
                                        { t('crm-views.incident.yes') }
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-danger flex-grow-1 flex-md-grow-0'
                                        onClick={() => handleConfirmChoice(false)}
                                    >
                                        { t('crm-views.incident.no') }
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    { (showContact) &&
                    <div className='w-100 d-flex align-items-center justify-content-center'>
                        <div className='col-12 col-lg-5 d-flex flex-column align-items-center'>
                            <p>{ t('crm-views.incident.contact') }</p>
                            <p>{ t('crm-views.incident.contact2') } <span className='fw-bold'>{ t('crm-views.incident.contact3') }</span>.</p>

                            <div className='col-12 col-md-6'>
                                <table className='w-100 mt-4'>
                                    <tbody>
                                        <tr>
                                            <td>{ t('vende.form.placeholders.telefono') }</td>
                                            <td><a className='fw-bold' href='tel:+34621221547'>+34 621 221 547</a></td>
                                        </tr>
                                        <tr>
                                            <td>{ t('vende.form.placeholders.email') }</td>
                                            <td><a className='fw-bold' href='mailto:info@recomotor.com'>info@recomotor.com</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
}
